import * as config from "./apiConfig";
import axios from "axios";
import Qs from "qs";
import {v4 as uuid} from "uuid";
import ConnectionMonitor from "./connectionMonitor";

let xcorrelationid = uuid();
const axiosInstance = axios.create({
  paramsSerializer: function (params) {
    return Qs.stringify(params, { arrayFormat: "brackets" });
  },
  xsrfCookieName: "csrf-token",
  xsrfHeaderName: "X-CSRF-Token",
});

function getTime() {
  return new Date().getTime();
}

// Add a request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    config.__time = getTime();
    xcorrelationid = uuid();
    if (!config.__try) {
      config.__try = 1;
    }
    config.headers["X-CORRELATION-ID"] = xcorrelationid;
    return config;
  },
  (error) => {
    error.hmsg =
      "Error while communicating with the server. ID " + xcorrelationid + ".";
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosInstance.interceptors.response.use((response) => {
  let time = getTime() - response.config.__time;
  ConnectionMonitor.addNetworkEvent("ok", response.config.url, time);
  return response;
}, responseErrorHandler);

// handle errors
function responseErrorHandler(error) {
  if (error.response) {
    return responseErrorStatusHandler(error);
  } else {
    return timeoutHandler(error);
  }
}

// handle timeout
function timeoutHandler(error) {
  ConnectionMonitor.addNetworkEvent("timeout", error.config.url);
  return Promise.reject(error);
}

// handle error response
function responseErrorStatusHandler(error) {
  const response = error.response;

  switch (response.status) {
    case 401:
      ConnectionMonitor.addNetworkEvent(
        "error",
        error.config.url,
        response.status
      );
      return unAuthorizedHandler(error);
    case 502:
    case 503:
    case 504:
      // auto retry on 503, 502 and 504 with 200ms * (number of try) delay
      if (error.config.__try < config.NETWORK_ERROR_RETRY_COUNT + 1) {
        let delayRetry = new Promise(function (resolve) {
          setTimeout(function () {
            resolve();
          }, config.NETWORK_ERROR_RETRY_WAIT_MS * error.config.__try);
        });

        error.config.__try += 1;

        return delayRetry.then(function () {
          console.log(
            "Request to " +
              error.config.url +
              " failed with error status " +
              response.status +
              ". Retrying, attempt " +
              error.config.__try +
              "."
          );
          return axiosInstance(error.config);
        });
      } else {
        ConnectionMonitor.addNetworkEvent(
          "error",
          error.config.url,
          response.status
        );
        console.log(
          "Request to " +
            error.config.url +
            " failed with error status " +
            response.status +
            ". After " +
            (error.config.__try - 1) +
            " attempt(s)."
        );
      }
      break;
    default:
      ConnectionMonitor.addNetworkEvent(
        "error",
        error.config.url,
        response.status
      );
      break;
  }

  return Promise.reject(error);
}

// handle unauthorized response
function unAuthorizedHandler(error) {
  const response = error.response;
  let authHeader = response.headers["www-authenticate"];

  if (authHeader) {
    let [authScheme] = authHeader.split(" ");
    if (authScheme === "EKSessReload") {
      window.location.reload();
    }
  }

  return Promise.reject(error);
}

export default axiosInstance;
