import axios from "../axios";
import { initApi, BASE_URL } from "../initApi";

// ----------------------- APP - GLOBAL-ADMIN - GET GLOBAL USER LIST
export async function getGlobalUserList(data) {
  return initApi().then(() => {
    return axios.post(`${BASE_URL}/api/global-admin/user/paged`, data);
  });
}
// ----------------------- APP - GLOBAL-ADMIN - DELETE USER
export async function deleteUser(userId) {
  return initApi().then(() => {
    return axios.delete(`${BASE_URL}/api/global-admin/user/${userId}`);
  });
}
// ----------------------- APP - GLOBAL-ADMIN - GET USER BY ID
export async function getUserByID(id) {
  return initApi().then(() => {
    return axios.get(`${BASE_URL}/api/global-admin/${id}`);
  });
}
// ----------------------- APP - GLOBAL-ADMIN - PUT USER
export async function putUser(data) {
  return initApi().then(() => {
    return axios.put(`${BASE_URL}/api/global-admin/user`, data);
  });
}
// ----------------------- APP - GLOBAL-ADMIN - CHANGE COMPANY
export async function changeCompany(data) {
  return initApi().then(() => {
    return axios.put(`${BASE_URL}/api/global-admin/user/change-company`, data);
  });
}
// ----------------------- APP - GLOBAL-ADMIN - GET ROLES
export async function getUserRoles() {
  return initApi().then(() => {
    return axios.get(`${BASE_URL}/api/global-admin/user/roles`);
  });
}
// ----------------------- APP - GLOBAL-ADMIN - LOCK / UNLOCK USER
export async function lockUser(data) {
  return initApi().then(() => {
    return axios.put(`${BASE_URL}/api/global-admin/user/lock`, data);
  });
}
