import axios from "../axios";
import {initApi, BASE_URL } from "../initApi";

// ----------------------- SHOP - KNARR - GET FEED
export async function getFeed(params) {
  return initApi().then(() => {
    return axios.get(`${BASE_URL}/api/knarr/feed/?${params}`);
  });
}
// ----------------------- SHOP - KNARR - GET CAROUSEL SLIDES
export async function getCarouselSlides(params) {
  const paramString = params?.lang ? "?lang=" + params.lang : "";
  return initApi().then(() => {
    return axios.get(`${BASE_URL}/api/knarr/slides${paramString}`);
  });
}
// ----------------------- SHOP - KNARR - GET ALL CAROUSEL SLIDES
export async function getCarouselSlidesAll(params) {
  return initApi().then(() => {
    return axios.get(`${BASE_URL}/api/knarr/slides/all`);
  });
}
// ----------------------- APP - GLOBAL-ADMIN - POST CAROUSEL SLIDES
export async function postCarouselSlides(data) {
  return initApi().then(() => {
    return axios.post(`${BASE_URL}/api/knarr/slides`, data);
  });
}
// ----------------------- APP - GLOBAL-ADMIN - PUT CAROUSEL SLIDE IMAGE
export async function putCarouselSlideImage(data) {
  const formData = new FormData();
  formData.append("file", data.file);
  return initApi().then(() => {
    return axios.put(
      `${BASE_URL}/api/knarr/slides/image/${data.slideId}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
    });
  });
}
// ----------------------- APP - GLOBAL-ADMIN - PUT CAROUSEL SLIDE TEXT
export async function putCarouselSlideText(data) {
  return initApi().then(() => {
    return axios.put(
      `${BASE_URL}/api/knarr/slides/text/${data.slideId}`,
      data.body
    );
  });
}
// ----------------------- APP - GLOBAL-ADMIN - DELETE CAROUSEL SLIDE
export async function deleteCarouselSlide(slideId) {
  return initApi().then(() => {
    return axios.delete(`${BASE_URL}/api/knarr/slides/${slideId}`);
  });
}
