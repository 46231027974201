import { createSelector } from "reselect";
import { version } from "../../package.json";
import { apiserver } from "../api/apiServer";
import { setDefaultCartData } from "./shopReducer";

const PREFIX = version + "_";
const ORDER_SET_ORDER_HISTORY = `${PREFIX}ORDER_SET_ORDER_HISTORY`;
const ORDER_SET_ORDER = `${PREFIX}ORDER_SET_ORDER`;
const ORDER_SET_ORDER_META = `${PREFIX}ORDER_SET_ORDER_META`;

export const getReducerName = () => PREFIX + "orderHistoryReducer";
export const getOrderHistoryReducerState = (state) => state[getReducerName()];

export const initialState = {
  // meta: {},
  orderHistory: [],
  order: {},
  orderMeta: {
    page: 1,
    size: 10
  }
};

// -------------- GETTERS --------------
export const getOrderHistory = createSelector(
  getOrderHistoryReducerState,
  (orderHistoryReducer) => orderHistoryReducer.orderHistory
);
export const getOrder = createSelector(
  getOrderHistoryReducerState,
  (orderHistoryReducer) => orderHistoryReducer.order
);
export const getOrderMeta = createSelector(
  getOrderHistoryReducerState,
  (orderHistoryReducer) => orderHistoryReducer.orderMeta
);

// -------------- SETTERS --------------
export function setOrderHistory(data) {
  return {
    type: ORDER_SET_ORDER_HISTORY,
    data,
  };
}
export function setOrder(data) {
  return {
    type: ORDER_SET_ORDER,
    data,
  };
}
export function setOrderMeta(data) {
  return {
    type: ORDER_SET_ORDER_META,
    data,
  };
}

// -------------- HANDLERS --------------
export function fetchOrderHistory(data) {
  return async (dispatch, getState) => {
    const state = getState()[getReducerName()];
    const orderMeta = { ...state.orderMeta, ...data };

    
    dispatch(setOrderHistory([]));

    let response = undefined;
    try {
      response = await apiserver.order.getOrderHistory({
        page: orderMeta.page,
        size: orderMeta.size
      });
    } catch (e) {
      console.log({ e });
      return;
    }
    const ordersFormatted = response.data.data.map((order => {
      if (Object.keys(order.orderDocuments).length > 0) {
        //grab the first entry in order confirmation
        let orderConfirmationDocument = order.orderDocuments?.OrderConfirmation?.[0];
        let packingSlipDocument = order.orderDocuments?.PackingSlip?.[0];
        
        let invoiceDocument = null;
        if (order.orderDocuments?.InvoiceCorrections?.length > 0) {
          invoiceDocument = order.orderDocuments?.InvoiceCorrections[0];
        } else if (order.orderDocuments?.Invoice?.length > 0 ) {
          invoiceDocument = order.orderDocuments?.Invoice[0];
        }
        return {
          ...order,
          invoiceDocument,
          orderConfirmationDocument,
          packingSlipDocument,
        };
      }

      return order;
    }));
    const newOrderHistory = {
      ...response.data,
      data: ordersFormatted,
    };

    dispatch(setOrderHistory(newOrderHistory));
  };
}

export function handleOrderPaginationChange(data) {
  return async (dispatch) => {
    dispatch(setOrderMeta(data));
    dispatch(fetchOrderHistory(data));
  };
}

export function fetchOrder() {
  return async (dispatch) => {
    let response = undefined;
    try {
      response = await apiserver.order.getOrder();
    } catch (e) {
      console.log({ e });
      return;
    }

    dispatch(setOrder(response.data));
  };
}
export function handleReorder(data) {
  return async (dispatch) => {
    let response = undefined;
    data?.callback({ status: "loading" });
    try {
      response = await apiserver.order.reorder(data.params);
    } catch (e) {
      console.log({ e });
    data?.callback({ status: "error" });
      return;
    }
    data?.callback({ status: "success" });

    dispatch(setDefaultCartData(response.data));
  };
}

export function reducer(state = initialState, action) {
  switch (action.type) {
    case ORDER_SET_ORDER_HISTORY:
      return {
        ...state,
        orderHistory: action.data,
      };
    case ORDER_SET_ORDER:
      return {
        ...state,
        order: action.data,
      };
    case ORDER_SET_ORDER_META:
      return {
        ...state,
        orderMeta: { ...state.orderMeta, ...action.data }
      };
    default:
      return state;
  }
}