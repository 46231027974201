import { createSelector } from "reselect";
import { version } from "../../package.json";
import { apiserver } from "../api/apiServer";

const PREFIX = version + "_";
const SEL_EJ_SET_FILTERDATA = `${PREFIX}SEL_EJ_SET_FILTERDATA`;
const SEL_EJ_SET_PRODUCT_FILTERS = `${PREFIX}SEL_EJ_SET_PRODUCT_FILTERS`;
const SEL_EJ_SET_RESULTDATA = `${PREFIX}SEL_EJ_SET_RESULTDATA`;

export const getReducerName = () => PREFIX + "selectorEjectorsReducer";
export const getSelectorEjectorsReducerState = (state) => state[getReducerName()];

export const initialState = {
  filterData: {
    categoryId: "7c6d0ea9-2db8-4107-89bd-24f57ed67cdf",
    subCategoryIds: [],
    pageRequest: {
      page: 1,
      size: 999,
    },
  },
  resultData: [],
  productFilter: {
    filters:[],
    selectedFilters: [],
    currentFilters: {},
  },
};

// -------------- GETTERS --------------
export const getFilterData = createSelector(
  getSelectorEjectorsReducerState,
  (selectorEjectorsReducer) => selectorEjectorsReducer.filterData
);
export const getResultData = createSelector(
  getSelectorEjectorsReducerState,
  (selectorEjectorsReducer) => selectorEjectorsReducer.resultData
);
export const getProductFilter = createSelector(
  getSelectorEjectorsReducerState,
  (selectorEjectorsReducer) => selectorEjectorsReducer.productFilter
);

// -------------- SETTERS --------------
export function setFilterData(data) {
  return {
    type: SEL_EJ_SET_FILTERDATA,
    data,
  };
}
export function setResultData(data) {
  return {
    type: SEL_EJ_SET_RESULTDATA,
    data,
  };
}
export function setProductFilter(data) {
  return {
    type: SEL_EJ_SET_PRODUCT_FILTERS,
    data,
  };
}

// -------------- HANDLERS --------------
export function fetchFilters(data) {
  return async (dispatch, getState) => {
    const state = getState()[getReducerName()];
    const { filterData, productFilter } = state;

    let response = undefined;
    try {
      response = await apiserver.product.filterProduct({...filterData, filters: productFilter?.selectedFilters, ...data});
    } catch (e) {
      console.log({ e });
      return;
    }

    const currentFilters = {};
    if (
      response.data.selectedFilters &&
      response.data.selectedFilters.length > 0
    ) {
      response.data.selectedFilters.forEach((filter) => {
        currentFilters[filter.name] = filter.values;
      });
    }
    
    dispatch(
      setProductFilter({
        ...response.data,
        currentFilters,
      })
    );
  };
}
export function changeFilterData(data) {
  return async (dispatch, getState) => {
    const { filterData } = data;
    const state = getState()[getReducerName()];
    const oldFilterData = {...state.filterData};

    const combinedFilterData = { ...oldFilterData, ...filterData, filters: [] };
    dispatch(setFilterData(combinedFilterData));
      dispatch(fetchFilters(combinedFilterData));
      //dispatch(fetchResultData(combinedFilterData));
  };
}
/* export function handleFilterChange(data) {
  return async (dispatch, getState) => {
    const { selectedFilters } = data;
    const state = getState();
    const oldSelectedFilters = { ...state[getReducerName()].productFilter.selectedFilters };
    const filterData = {
      ...state[getReducerName()].filterData,
    };

    const combinedFilterData = {
      ...filterData,
      ...oldSelectedFilters,
      ...selectedFilters,
    };
    dispatch(setFilterData(combinedFilterData));
    dispatch(fetchFiltes(combinedFilterData));
    dispatch(fetchResultData(combinedFilterData));
  };
} */

export function updateSelectorFilters(data) {
  return async (dispatch, getState) => {

    const state = getState()[getReducerName()];
    const filters = state.productFilter;
    const filterData = state.filterData;
    let eventData = data?.event || {}


    const newCurrentFilters = { ...filters.currentFilters };
    if (eventData.value && eventData.value !== null) {
      newCurrentFilters[eventData.key] = eventData.value;
    } else if (eventData) {
      delete newCurrentFilters[eventData.key];
    }

    const newSelectedFilters = Object.keys(newCurrentFilters).map((key) => {
      return {
        name: key,
        values: Array.isArray(newCurrentFilters[key])
        ? newCurrentFilters[key]
        : [newCurrentFilters[key]],
      };
    });

    dispatch(
      setProductFilter({
        ...filters,
        currentFilters: newCurrentFilters,
        selectedFilters: newSelectedFilters,
      })
    );

    if (eventData.commit === true) {
      dispatch(
        fetchFilters({
          ...filterData,
          filters: newSelectedFilters,
        })
      );

      if (newSelectedFilters?.length >= 1) {
        dispatch(
          fetchResultData({
            ...filterData,
            filters: newSelectedFilters,
          })
        );
      } else {
        dispatch(setResultData({}));
      }
    }
  };
}



export function fetchResultData(data) {
  return async (dispatch) => {
    let response = undefined;
    
    dispatch(
      setResultData({})
    );
    try {
      response = await apiserver.product.searchProduct({
        ...data,
        withDimensions: true,
      });
    } catch (e) {
      console.log({ e });
      return;
    }
    dispatch(setResultData(response.data));
  };
}

export function reducer(state = initialState, action) {
  switch (action.type) {
    case SEL_EJ_SET_FILTERDATA:
      return {
        ...state,
        filterData: action.data,
      };
    case SEL_EJ_SET_RESULTDATA:
      return {
        ...state,
        resultData: action.data,
      };
    case SEL_EJ_SET_PRODUCT_FILTERS:
      return {
        ...state,
        productFilter: action.data,
      };
    default:
      return state;
  }
}
