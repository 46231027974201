import axios from "../axios";
import { initApi, BASE_URL } from "../initApi";

// ----------------------- APP - PRODUCT DIMENSION
export async function productDimension(productId) {
  return initApi().then(() => {
    return axios.get(`${BASE_URL}/api/product-dimension/${productId}`);
  });
}
// ----------------------- APP - PRODUCT DIMENSION TOLERANCE-PRICE
export async function fetchTolerancePrice(data) {
  return initApi().then(() => {
    return axios.post(
      `${BASE_URL}/api/product-dimension/tolerance-price`,
      { 
        dimensionId: data.dimensionId, 
        changedLength: data.changedLength 
      }
    );
  });
}