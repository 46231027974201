import axios from "../axios";
import { initApi, BASE_URL } from "../initApi";

// ----------------------- APP - LANGUAGE - GET LANGUAGE
export async function getLanguage(productId) {
  return initApi().then(() => {
    return axios.get(`${BASE_URL}/api/language`);
  });
}

// ----------------------- APP - LANGUAGE - PUT LANGUAGE
export async function putLanguage(lang) {
  return initApi().then(() => {
    return axios.put(`${BASE_URL}/api/language`, lang);
  });
}

// ----------------------- APP - LANGUAGE - GET LANGUAGE LIST
export async function getLanguageList() {
  return initApi().then(() => {
    return axios.get(`${BASE_URL}/api/language/list`);
  });
}

