import axios from "../axios";
import { initApi, BASE_URL } from "../initApi";

// ----------------------- APP - ADDRESS - GET ADDRESSES
export async function getAddresses() {
  return initApi().then(() => {
    return axios.get(`${BASE_URL}/api/address`);
  });
}

// ----------------------- APP - ADDRESS - GET ADDRESS BY ID
export async function getAddressById(id) {
  return initApi().then(() => {
    return axios.get(`${BASE_URL}/api/address/${id}`);
  });
}
// ----------------------- APP - ADDRESS - DELETE ADDRESS
export async function deleteAddress(id) {
  return initApi().then(() => {
    return axios.delete(`${BASE_URL}/api/address/${id}`);
  });
}

// ----------------------- APP - ADDRESS - POST ADDRESS
export async function postAddress(data) {
  return initApi().then(() => {
    return axios.post(`${BASE_URL}/api/address`, data);
  });
}
// ----------------------- APP - ADDRESS - PUT ADDRESS
export async function updateAddress(data) {
  return initApi().then(() => {
    return axios.put(`${BASE_URL}/api/address`, data);
  });
}