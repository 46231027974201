import { createSelector } from "reselect";
import { version } from "../../package.json";
import { apiserver } from "../api/apiServer";

const PREFIX = version + "_";
const USER_ADM_SET_USERLIST = `${PREFIX}USER_ADM_SET_USERLIST`;
const USER_ADM_SET_COMPANY = `${PREFIX}USER_ADM_SET_COMPANY`;
const USER_ADM_SET_CURRENT_USER = `${PREFIX}USER_ADM_SET_CURRENT_USER`;
const USER_ADM_SET_ROLES = `${PREFIX}USER_ADM_SET_ROLES`;
const USER_ADM_SET_USERLIST_META = `${PREFIX}USER_ADM_SET_USERLIST_META`;

export const getReducerName = () => PREFIX + "userAdmReducer";
export const getUserAdmReducerState = (state) => state[getReducerName()];

export const initialState = {
  userList: {},
  userListMeta: {
    pageRequest: { page: 1, size: 10 },
    userFilter: {
      email: "",
      company: "",
      customerNumber: "",
    },
  },
  company: {},
  currentUser: {},
  userRoles: [],
};

// -------------- GETTERS --------------
export const getUserList = createSelector(
  getUserAdmReducerState,
  (userAdmReducer) => userAdmReducer.userList
);
export const getCompany = createSelector(
  getUserAdmReducerState,
  (userAdmReducer) => userAdmReducer.company
);
export const getCurrentUser = createSelector(
  getUserAdmReducerState,
  (userAdmReducer) => userAdmReducer.currentUser
);
export const getUserRoles = createSelector(
  getUserAdmReducerState,
  (userAdmReducer) => userAdmReducer.userRoles
);
export const getUserListMeta = createSelector(
  getUserAdmReducerState,
  (userAdmReducer) => userAdmReducer.userListMeta
);

// -------------- SETTERS --------------
export function setUserList(data) {
  return {
    type: USER_ADM_SET_USERLIST,
    data,
  };
}
export function setCompany(data) {
  return {
    type: USER_ADM_SET_COMPANY,
    data,
  };
}
export function setCurrentUser(data) {
  return {
    type: USER_ADM_SET_CURRENT_USER,
    data,
  };
}
export function setUserRoles(data) {
  return {
    type: USER_ADM_SET_ROLES,
    data,
  };
}
export function setUserListMeta(data) {
  return {
    type: USER_ADM_SET_USERLIST_META,
    data,
  };
}
// -------------- HANDLERS --------------
export function fetchUserList(data) {
  return async (dispatch, getState) => {
    const state = getState()[getReducerName()];
    const requestParams = {
      pageRequest: {
        ...state.userListMeta.pageRequest,
        ...data?.pageRequest,
      },
      userFilter: { ...state.userListMeta.userFilter, ...data?.userFilter },
    };
    let response = undefined;

    try {
      response = await apiserver.adminUser.getUsersPaged(requestParams);
    } catch (e) {
      console.log({ e });
      return;
    }
    dispatch(setUserList(response.data));
    dispatch(setUserListMeta(requestParams));
  };
}
export function fetchCompany() {
  return async (dispatch) => {
    let response = undefined;
    try {
      response = await apiserver.adminCompany.company();
    } catch (e) {
      console.log({ e });
      return;
    }
    dispatch(setCompany(response.data));
  };
}
export function fetchCurrentUser(data) {
  return async (dispatch) => {
    let response = undefined;
    try {
      response = await apiserver.adminUser.getUserByID(data.id);
    } catch (e) {
      console.log({ e });
      return;
    }
    dispatch(setCurrentUser(response.data));
  };
}
export function changeUserInformation(data) {
/* return async (dispatch, getState) => {
    const state = getState();
    const currentUser = state[getReducerName()].currentUser;
    
    try {
      await apiserver.adminUser.putUser(data)
    } catch (e) {
    console.log({ e });
    return;
    }
    dispatch(fetchCurrentUser(currentUser));
  }; */
  return async (dispatch, getState) => {
    const state = getState();
    const currentUser = state[getReducerName()].currentUser;
    const newCurrentUser = { ...currentUser, ...data }
 
    dispatch(setCurrentUser(newCurrentUser));
  }; 
}

export function submitUserInformation(data) {
  return async (dispatch, getState) => {
    const state = getState();
    const currentUser = state[getReducerName()].currentUser;
    const formattedCurrentUser = {
      id: currentUser.id,
      salutation: currentUser.salutation,
      phoneNumber: currentUser.phoneNumber,
      mobilePhoneNumber: currentUser.mobilePhoneNumber,
      fax: currentUser.fax,
      email: currentUser.email,
      firstName: currentUser.firstName,
      lastName: currentUser.lastName,
      password: currentUser.password,
      roles: currentUser.roles,
    };
    try {
      await apiserver.adminUser.putUser(formattedCurrentUser);
    } catch (e) {
      console.log({ e });
      data?.callback({
        isSnackbarOpen: true,
        msgSnackbar: "msgUpdateUserError",
        severity: "error",
      });
      return;
    }
    data?.callback({
      isSnackbarOpen: true,
      msgSnackbar: "msgUpdateUserSuccess",
      severity: "success",
    });
    dispatch(fetchCurrentUser(currentUser));
  };
}

export  function fetchUserRoles() {
  return async (dispatch) => {
    let response = undefined;
    try {
      response = await apiserver.adminUser.getUserRoles();
    } catch (e) {
      console.log({ e });
      return;
    }
    dispatch(setUserRoles(response.data));
  };
}

export function handleDeleteUser(data) {
  return async (dispatch) => {

    if (data?.id) {
      try {
        await apiserver.adminUser.deleteUserByID(data.id);
      } catch (e) {
        console.log({ e });
        data?.callback({
          isSnackbarOpen: true,
          msgSnackbar: "msgUserDeleteError",
          severity: "error",
        });
        return;
      }
      data?.callback({
        isSnackbarOpen: true,
        msgSnackbar: "msgUserDeleteSuccess",
        severity: "success",
      });
      dispatch(fetchUserList());
    }
  };
}

export function handleLockUser(data) {
  return async (dispatch) => {
    if (data?.params?.userId) {
      try {
        await apiserver.adminUser.lockUser(data.params);
      } catch (e) {
        console.log({ e });
        data?.callback({
          isSnackbarOpen: true,
          msgSnackbar: "msgUserLockError",
          severity: "error",
        });
        return;
      }
      data?.callback({
        isSnackbarOpen: true,
        msgSnackbar:
          data.params.locking === true
            ? "msgUserLockSuccess"
            : "msgUserUnlockSuccess",
        severity: "success",
      });
      dispatch(fetchUserList());
    }
  };
}

export function reducer(state = initialState, action) {
  switch (action.type) {
    case USER_ADM_SET_USERLIST:
      return {
        ...state,
        userList: action.data,
      };
    case USER_ADM_SET_COMPANY:
      return {
        ...state,
        company: action.data,
      };
    case USER_ADM_SET_CURRENT_USER:
      return {
        ...state,
        currentUser: action.data,
      };
    case USER_ADM_SET_ROLES:
      return {
        ...state,
        userRoles: action.data,
      };
    case USER_ADM_SET_USERLIST_META:
      return {
        ...state,
        userListMeta: action.data,
      };
    default:
      return state;
  }
}
