import axios from "../axios";
import { initApi, BASE_URL } from "../initApi";
import { saveAs } from "file-saver";

// ----------------------- SHOP - CART - GET DEFAULT (ACTIVE) CART DATA
export async function getDefaultCart() {
  return initApi().then(() => {
    return axios.get(`${BASE_URL}/api/cart/default`);
  });
}

// ----------------------- SHOP - CART - UPDATE DEFAULT (ACTIVE) CART DATA
export async function setDefaultCart(cartId) {
  return initApi().then(() => {
    return axios.put(`${BASE_URL}/api/cart/default`, cartId);
  });
}

// ----------------------- SHOP - CART - GET CART DATA
export async function getCartData() {
  return initApi().then(() => {
    return axios.get(`${BASE_URL}/api/cart`);
  });
}

// ----------------------- SHOP - CART - GET SINGLE CART DATA
export async function getSingleCartData(cartId) {
  return initApi().then(() => {
    return axios.get(`${BASE_URL}/api/cart/${cartId}`);
  });
}

// ----------------------- SHOP - CART - SUBMIT SINGLE CART DATA
export async function submitCartData(data) {
  return initApi().then(() => {
    return axios.post(`${BASE_URL}/api/cart`, data);
  });
}

// ----------------------- SHOP - CART - SUBMIT CART POSITION
export async function submitCartPosition(data) {
  return initApi().then(() => {
    return axios.post(`${BASE_URL}/api/cart/position`, data);
  });
}
// ----------------------- SHOP - CART - SUBMIT CART POSITION DIRECT
export async function submitCartPositionDirect(data) {
  return initApi().then(() => {
    return axios.post(`${BASE_URL}/api/cart/position/direct`, data);
  });
}
// ----------------------- SHOP - CART - SUBMIT CONFIGURATOR CART POSITION
export async function submitConfiguratorCartPosition(data) {
  return initApi().then(() => {
    return axios.post(`${BASE_URL}/api/cart/configurator`, data);
  });
}
// ----------------------- SHOP - CART - REPLACE CONFIGURATOR CART POSITION
export async function replaceConfiguratorCartPosition(data) {
  return initApi().then(() => {
    return axios.put(`${BASE_URL}/api/cart/configurator/replace`, data);
  });
}

// ----------------------- SHOP - CART - REPLACE CART POSITION
export async function replaceCartPosition(data) {
  return initApi().then(() => {
    return axios.put(
      `${BASE_URL}/api/cart/position/replace`,
      data
    );
  });
}
// ----------------------- SHOP - CART - UPDATE CART DATA
export async function updateCartData(data) {
  return initApi().then(() => {
    return axios.put(`${BASE_URL}/api/cart`, data);
  });
}

// ----------------------- SHOP - CART - UPDATE CART POSITION
export async function updateCartPosition(data) {
  return initApi().then(() => {
    return axios.put(`${BASE_URL}/api/cart/position`, data);
  });
}

// ----------------------- SHOP - CART - DELETE CART DATA
export async function deleteCartData(cartId) {
  return initApi().then(() => {
    return axios.delete(`${BASE_URL}/api/cart/${cartId}`);
  });
}

// ----------------------- SHOP - CART - DELETE CART DATA
export async function deleteCartPosition(positionId) {
  return initApi().then(() => {
    return axios.delete(`${BASE_URL}/api/cart/position/${positionId}`);
  });
}

// ----------------------- SHOP - CART - CLEAR CART DATA
export async function clearCartData(data) {
  return initApi().then(() => {
    return axios.put(`${BASE_URL}/api/cart/clear`, data);
  });
}


// ----------------------- SHOP - CART - EXPORT PARTS LIST
export async function exportPartsList(data) {
  return initApi().then(() => {
    return axios
      .get(`${BASE_URL}/api/cart/export/${data.cartId}`, {
        headers: {
          Accept:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        },
        responseType: "blob",
      })
      .then((response) => {
        const blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(blob, "knarr-" + data.name + ".xlsx");
      });
  });
}

// ----------------------- SHOP - CART - IMPORT PARTS LIST
export async function importPartsList(data) {
  return initApi().then(() => {
    return axios.post(`${BASE_URL}/api/cart/import`, data);
  });
}
// ----------------------- SHOP - CART - IMPORT PARTS LIST FROM FILE
/* export async function importPartsListFile(data) {
  const formData = new FormData();
  formData.append("file", data.file)
  return initApi().then(() => {
    return axios.post(`${BASE_URL}/api/cart/import`, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  });
} */
// ----------------------- SHOP - CART - SAVE CART
export async function saveCart(data) {
  return initApi().then(() => {
    return axios
      .get(`${BASE_URL}/api/cart/save/${data.cartId}`, {
        headers: {
          Accept:
            "application/json",
        },
        responseType: "blob",
      })
      .then((response) => {
        const blob = new Blob([response.data], {
          type: "application/json",
        });
        saveAs(blob, "knarr-" + data.name + ".knarr");
      });
  });
}

// ----------------------- SHOP - CART - LOAD CART
export async function loadCart(file) {
  const formData = new FormData();
  formData.append("file", file);
  return initApi().then(() => {
    return axios.post(`${BASE_URL}/api/cart/load`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  });
}
// ----------------------- SHOP - CART - GET CONFIGURATION
export async function getConfiguration(positionId) {
  return initApi().then(() => {
    return axios.get(
      `${BASE_URL}/api/cart/configurator/${positionId}`
    );
  });
}
// ----------------------- SHOP - CART - GET CONFIGURATION STEP FILE
export async function getConfigurationStepFile(groupId) {
  return initApi().then(() => {
    return axios
      .get(`${BASE_URL}/api/cart/configurator/step/${groupId}`, {
        headers: {
          Accept: "application/json",
        },
        responseType: "blob",
      })
      .then((response) => {
        const blob = new Blob([response.data], {
          type: "application/json",
        });
        saveAs(blob, "knarr-configuration" + groupId + ".step");
      });
  });
}