import * as appReducer from "./appReducer";
import * as userReducer from "./userReducer";
import * as shopReducer from "./shopReducer";
import * as userAdmReducer from "./userAdmReducer";
import * as orderHistoryReducer from "./orderHistoryReducer";
import * as statsReducer from "./statsReducer";
import * as globalAdmReducer from "./globalAdmReducer";
import * as selectorEjectorsReducer from "./selectorEjectorsReducer";

const reducers = {
  [appReducer.getReducerName()]: appReducer.reducer,
  [userReducer.getReducerName()]: userReducer.reducer,
  [shopReducer.getReducerName()]: shopReducer.reducer,
  [userAdmReducer.getReducerName()]: userAdmReducer.reducer,
  [orderHistoryReducer.getReducerName()]: orderHistoryReducer.reducer,
  [statsReducer.getReducerName()]: statsReducer.reducer,
  [globalAdmReducer.getReducerName()]: globalAdmReducer.reducer,
  [selectorEjectorsReducer.getReducerName()]: selectorEjectorsReducer.reducer,
};

export default reducers;
