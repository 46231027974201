import { createSelector } from "reselect";
import { version } from "../../package.json";
import { apiserver } from "../api/apiServer";
import {
  fetchShoppingCarts,
  fetchDefaultShoppingCart,
  updateMeta,
} from "./shopReducer";

const PREFIX = version + "_";
const USER_FETCH_USER = `${PREFIX}USER_REDUCER_FETCH_USER`;
const USER_SET_USERFORM = `${PREFIX}USER_SET_USERFORM`;
const USER_SET_ADDRESSES = `${PREFIX}USER_SET_ADDRESSES`;
const USER_SET_SERVER_ERRS = `${PREFIX}  USER_SET_SERVER_ERRS`;

export const getReducerName = () => PREFIX + "userReducer";
export const getuserReducerState = (state) => state[getReducerName()];

export const initialState = {
  user: {},
  userForm: {
    username: "",
    customerNo: "",
    zip: "",
    companyName: "",
    companyAddition: "",
    companyAddress: "",
    companyAddressAddition: "",
    companyZip: "",
    companyCity: "",
    companyCountry: undefined,
    companyVAT: "",
    companyPhoneNumber: "",
    companyFax: "",
    companySupplierNumber: "",
    // user
    salutation: "",
    firstName: "",
    lastName: "",
    email: "",
    emailRepeat: "",
    password: "",
    passwordRepeat: "",
    phoneNumber: "",
    fax: "",
    mobilePhoneNumber: "",
  },
  addresses: [],
  serverErrs: null,
};

// -------------- GETTERS --------------
export const getUserForm = createSelector(
  getuserReducerState,
  (userReducer) => userReducer.userForm
);
export const getUser = createSelector(
  getuserReducerState,
  (userReducer) => userReducer.user
);
export const getAddresses = createSelector(
  getuserReducerState,
  (userReducer) => userReducer.addresses
);
export const getServerErrs = createSelector(
  getuserReducerState,
  (userReducer) => userReducer.serverErrs
);

// -------------- SETTERS --------------
export function setUser(data) {
  return {
    type: USER_FETCH_USER,
    data,
  };
}
export function setUserForm(data) {
  return {
    type: USER_SET_USERFORM,
    data,
  };
}
export function setAddresses(data) {
  return {
    type: USER_SET_ADDRESSES,
    data,
  };
}
export function setServerErrs(data) {
  return {
    type:  USER_SET_SERVER_ERRS,
    data
  }
}
// -------------- HANDLERS --------------
export function fetchUser() {
  return async (dispatch) => {
    let response = undefined;
    try {
      response = await apiserver.user.me();
    } catch (e) {
      console.log({ e });
      return;
    }
    dispatch(setUser(response.data));
  };
}

export function fetchAddresses() {
  return async (dispatch) => {
    let response = undefined;
    try {
      response = await apiserver.address.getAddresses();
    } catch (e) {
      console.log({ e });
      return;
    }
    dispatch(setAddresses(response.data));
    const firstInvoiceAddress = response.data.find(address => address.addressType === "Invoice");
    dispatch(
      updateMeta({
        deliveryAddressId: typeof response.data[0] === "object" ? response.data[0].id : null,
        invoiceAddressId: typeof firstInvoiceAddress === "object" ? firstInvoiceAddress.id : null,
      })
    );
  };
}
export function submitAddress(data) {
  return async (dispatch) => {

    data?.callback("loading");
    try {
      await apiserver.address.postAddress(data.fields);
    } catch (e) {
      console.log({ e });
      data?.callback("error");
      return;
    }
    data?.callback("success");

    dispatch(fetchAddresses());
  };
}
export function requestPasswordReset(data) {
  return async (dispatch, getState) => {
    const state = getState();
    const userForm = state[getReducerName()].userForm;

    data?.callback({ statusReset: "loading", page: "resetPassword" });
    try {
      await apiserver.passwordReset.requestPasswordReset({ email: userForm.username });
    } catch (e) {
      if (e.response.data) {
        data?.callback({ statusReset: "error", err: e.response.data });
      }
      data?.callback({ statusReset: "error" });
      return;
    }
    data?.callback({ statusReset: "success" })
  }
}
export function handlePasswordReset(data) {
  return async (dispatch, getState) => {
    const state = getState();
    const userForm = state[getReducerName()].userForm;
    data?.callback({ statusReset: "loading" });
    try {
      await apiserver.passwordReset.resetPassword({
        email: userForm.username,
        token: data.token,
        newPassword: userForm.password,
      });
    } catch (e) {
      if (e.response.data.message) {
        data?.callback({ statusReset: "error", err: e.response.data.message });
      } else {
        data?.callback({ statusReset: "error" });
      }
      return;
    }
    data?.callback({ statusReset: "success"})
  };
}
export function deleteAddress(data) {
  return async (dispatch, getState) => {
    const state = getState();
    const addresses = state[getReducerName()].addresses;

    try {
      await apiserver.address.deleteAddress(data.addressId);
    } catch (e) {
      console.log({ e });
      //data?.callback({ status: "error" });
      return;
    }
    const newAddresses = [...addresses].filter((address) => {
      return address.id !== data.addressId;
    });
    dispatch(setAddresses(newAddresses));

    //data?.callback({ status: "success" });
  };
}
export function updateUserForm(data) {
  return async (dispatch) => {
    dispatch(setUserForm(data));
  };
}
export function updateServerErrs(data) {
  return async (dispatch) => {
    dispatch(setServerErrs(data));
  };
}
export function handleAdminAddUser(data) {
  return async (dispatch, getState) => {
    const state = getState();
    const userForm = { ...state[getReducerName()].userForm };

    Object.keys(userForm).forEach((key) => {
      const attribute = userForm[key];
      if (attribute === "") {
        userForm[key] = null;
      }
    });

    const signupData = {
      salutation: userForm.salutation,
      firstName: userForm.firstName,
      lastName: userForm.lastName,
      password: userForm.password,
      email: userForm.email,
      phoneNumber: userForm.phoneNumber,
      mobilePhoneNumber: userForm.mobilePhoneNumber,
      fax: userForm.fax,
      roles:[]
    };
    data?.callback({ status: "loading" });
    try {
      await apiserver.adminUser.postUser(signupData);
    } catch (e) {
      if (e.response.data.message) {
        data?.callback({ status: "error", err: e.response.data.message });
      } else {
        data?.callback({ status: "error" });
      }
      return;
    }
    data?.callback({ status: "success" });
  };
}
export function handleSignUp(data) {
  return async (dispatch, getState) => {
    const state = getState();
    const userForm = {...state[getReducerName()].userForm};

    Object.keys(userForm).forEach(key => {
      const attribute = userForm[key];
      if (attribute === "") {
        userForm[key] = null;
      }
    })

    const signupData = {
      companyName: userForm.companyName,
      street: userForm.companyAddress,
      streetNumber: userForm.companyAddressAddition,
      zip: userForm.companyZip,
      city: userForm.companyCity,
      vatId: userForm.companyVAT,
      country: userForm.companyCountry,
      phoneNumber: userForm.companyPhoneNumber,
      fax: userForm.companyFax,
      user: {
        salutation: userForm.salutation,
        firstName: userForm.firstName,
        lastName: userForm.lastName,
        password: userForm.password,
        email: userForm.email,
        phoneNumber: userForm.phoneNumber,
        mobilePhoneNumber: userForm.mobilePhoneNumber,
        fax: userForm.fax,
      },
    };
    data?.callback({ status: "loading" });
    try {
      await apiserver.user.register(signupData);
    } catch (e) {
      if (e.response.data.message) {
        data?.callback({ status: "error", err: e.response.data.message });
      } else {
        data?.callback({ status: "error" });
      }
      return;
    }
    data?.callback({ status: "success" });
  };
}
export function handleSignUpWithCompany(data) {
  return async (dispatch, getState) => {
    const state = getState();
    const userForm = { ...state[getReducerName()].userForm };

    Object.keys(userForm).forEach((key) => {
      const attribute = userForm[key];
      if (attribute === "") {
        userForm[key] = null;
      }
    });


    data?.callback({ status: "loading" });
    try {
      await apiserver.user.registerWithCompany({
        customerNumber: userForm.customerNo,
        zip: userForm.companyZip,
        user: {
          salutation: userForm.salutation || null,
          firstName: userForm.firstName || null,
          lastName: userForm.lastName || null,
          password: userForm.password || null,
          email: userForm.email || null,
          phoneNumber: userForm.phoneNumber || null,
          mobilePhoneNumber: userForm.mobilePhoneNumber || null,
          fax: userForm.fax || null,
        },
      });

    } catch (e) {
      if (e.response.data.message) {
        data?.callback({ status: "error", err: e.response.data.message });
      } else {
        data?.callback({ status: "error" });
      }

      return;
    }
    data?.callback({ status: "success" });
  };
}
export function fetchCompanyByCustomerNo(data) {
  return async (dispatch, getState) => {
    const state = getState();
    const userForm = state[getReducerName()].userForm;

    data?.callback({ statusCompany: "loading" });
    let response = null;
    try {
      response = await apiserver.user.getCompanyByCustomerNo({
        customerNumber: userForm.customerNo,
        zip: userForm.companyZip,
      });
    } catch (e) {
      if (e.response.data.message) {
        data?.callback({
          statusCompany: "error",
          err: e.response.data.message,
        });
      } else {
        data?.callback({ statusCompany: "error" });
      }
     
      return;
    }
    data?.callback({ statusCompany: "companyFound" });
    dispatch(setUserForm({
      companyName: response.data.name || "",
      companyAddition: response.data.nameAddition || "",
      companyAddress: response.data.street || "",
      companyAddressAddition: response.data.streetAddition || "",
      companyZip: response.data.zip || "",
      companyCity: response.data.city || "",
      companyCountry: response.data.country || "",
      companyVAT: response.data.vatId || "",
      companyPhoneNumber: response.data.phoneNumber || "",
      companyFax: response.data.fax || "",
    }));
  };
}

export function handleSignIn(data) {
  return async (dispatch, getState) => {
    const state = getState();
    const userForm = state[getReducerName()].userForm;
    let response = undefined;
    data?.callback({ statusSignIn: "loading" });
    try {
      response = await apiserver.user.authenticate({
        username: userForm.username,
        password: userForm.password
      });
    } catch (e) {
      console.log({ e });
      if ( e.response.data === 'RESET_PASSWORD' ) {
        data?.callback({ statusReset: "error", oldShopPwHint: "show" });
      } 

      data?.callback({ statusSignIn: "error", err: "invalidLoginData" });
      return;
    }
    data?.callback({ statusSignIn: "success" })

    dispatch(setUser(response.data));

    dispatch(fetchShoppingCarts());
    dispatch(fetchDefaultShoppingCart());

    const emptyUserForm = {};
    Object.keys(userForm).forEach((key) => {
      emptyUserForm[key] = "";
    });
    dispatch(setUserForm(emptyUserForm));
  };
}
export function handleSignOut() {
  return async (dispatch) => {
    try {
      await apiserver.user.logout();
    } catch (e) {
      console.log({ e });
      return;
    }
    window.location.reload();
  };
}

export function reducer(state = initialState, action) {
  switch (action.type) {
    case USER_FETCH_USER:
      return {
        ...state,
        user: action.data,
      };
    case USER_SET_USERFORM:
      return {
        ...state,
        userForm: { ...state.userForm, ...action.data },
      };
    case USER_SET_ADDRESSES:
      return {
        ...state,
        addresses: action.data,
      };
    case  USER_SET_SERVER_ERRS:
      return {
        ...state,
        serverErrs: { ...state.serverErrs, ...action.data },
      };
    default:
      return state;
  }
}
