import i18n from "i18next";
import XHR from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";


function loadLocales(url, options, callback, data) {

  try {
    let waitForLocale = require("./../language/locales/" + url + ".json");
    callback(waitForLocale, { status: "200" });
  } catch (e) {
    callback(null, { status: "404" });
  }
}

// Dirty fix - remove "currentLanguage" from localStorage if cookie "languageId" doesn't exist.
if (document.cookie.indexOf("languageId") < 0) {
  localStorage.removeItem("currentLanguage");
}

i18n
  .use(XHR)
  .use(initReactI18next)
  .init({
    debug: process.env["NODE_ENV"] === "development",
    //debug: true,
    lng: localStorage.getItem("currentLanguage") || "de",
    fallbackLng: "en",
    //preload: ['de', 'en'],

    ns: ["language"],
    defaultNS: "language",

    interpolation: {
      escapeValue: false, // not needed for react!!
    },
    keySeparator: false,
    joinArrays: false,
    returnObjects: false,

    // react i18next special options (optional)
    react: {
      useSuspense: true,
      nsMode: "default", // set it to fallback to let passed namespaces to translated hoc act as fallbacks
    },

    backend: {
      allowMultiLoading: false,
      loadPath: "{{lng}}/{{ns}}",
      ajax: loadLocales,
      parse: (data) => data,
    },
  });

export { i18n };