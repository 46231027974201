import axios from "axios";

const PUBLIC_URL = process.env.PUBLIC_URL ? process.env.PUBLIC_URL : ".";
export let BASE_URL = "";//config.FALLBACK_API_SERVER_URL;

let init = true; //TODO? set to false to reanable init per external serverConfig.json

export function initApi() {
  if (!init) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${PUBLIC_URL}/serverConfig.json`)
        .then((result) => {
          BASE_URL = result.data.crossUrl
            ? result.data.crossUrl
            : result.data.baseUrl;
          resolve(result);
          init = true;
        })
        .catch((result) => {
          reject(result);
          console.warn(
            `initApi can´t load url:  ${PUBLIC_URL}/serverConfig.json`
          );
        });
    });
  }
  return Promise.resolve();
}

