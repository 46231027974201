import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { I18nextProvider } from "react-i18next";
import { i18n } from "./i18n";
import { Provider } from "react-redux";
import loadable from "@loadable/component";
import reportWebVitals from "./reportWebVitals";
import { store } from "./store";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { initApi } from "./api";
import "./style/style.scss";

const App = loadable(() => import("./app/components/App/App.js"));

const createWithDefaultProps = (Component, props) => <Component {...props} />;

const baseName =
  process.env.PUBLIC_URL === "./" || process.env.PUBLIC_URL === "."
    ? ""
    : process.env.PUBLIC_URL;


const render = () => {
  ReactDOM.render(
    <I18nextProvider i18n={i18n} t={i18n.t}>
      <Suspense fallback={<div>Loading... </div>}>
        <Provider store={store} key="provider">
          <HelmetProvider>
            <React.Fragment>
              <Router
                createElement={createWithDefaultProps}
                basename={baseName}
              >
                <Route path="/" component={App} />
              </Router>
            </React.Fragment>
          </HelmetProvider>
        </Provider>
      </Suspense>
    </I18nextProvider>,
    document.getElementById("knr-app")
  );
};

initApi()
  .then(() => {
    render();
  })
  .catch(() => {
    render();
  });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
