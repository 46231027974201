import * as Address from "./controller/address";
import * as AdminUser from "./controller/adminUser";
import * as AdminCompany from "./controller/adminCompany";
import * as Cart from "./controller/cart";
import * as DimensionTableMapping from "./controller/dimensionTableMapping";
import * as GlobalAdmin from "./controller/global-admin";
import * as Knarr from "./controller/knarr";
import * as Language from "./controller/language";
import * as Order from "./controller/purchasedOrder";
import * as PasswordReset from "./controller/passwordReset";
import * as Product from "./controller/product";
import * as ProductCategory from "./controller/productCategory";
import * as ProductDimension from "./controller/productDimension";
import * as PurchasedOrder from "./controller/purchasedOrder";
import * as Resources from "./controller/resources";
import * as Statistics from "./controller/statistics";
import * as User from "./controller/user";

export { initApi } from "./initApi";

export const apiserver = {
  address: Address,
  adminUser: AdminUser,
  adminCompany: AdminCompany,
  cart: Cart,
  dimensionTableMapping: DimensionTableMapping,
  knarr: Knarr,
  globalAdmin: GlobalAdmin,
  language: Language,
  order: Order,
  passwordReset: PasswordReset,
  product: Product,
  productCategory: ProductCategory,
  productDimension: ProductDimension,
  purchasedOrder: PurchasedOrder,
  resources: Resources,
  statistics: Statistics,
  user: User,
};
