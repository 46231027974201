import axios from "../axios";
import { initApi, BASE_URL } from "../initApi";

// ----------------------- APP - ADMIN - GET USERS
export async function getUsers() {
  return initApi().then(() => {
    return axios.get(`${BASE_URL}/api/admin/user`);
  });
}

// ----------------------- APP - ADMIN - GET USERS PAGED
export async function getUsersPaged(data) {
  return initApi().then(() => {
    return axios.post(`${BASE_URL}/api/admin/user/paged`, data);
  });
}
// ----------------------- APP - ADMIN - GET USER BY ID
export async function getUserByID(id) {
  return initApi().then(() => {
    return axios.get(`${BASE_URL}/api/admin/user/${id}`);
  });
}
// ----------------------- APP - ADMIN - DELETE USER BY ID
export async function deleteUserByID(id) {
  return initApi().then(() => {
    return axios.delete(`${BASE_URL}/api/admin/user/${id}`);
  });
}
// ----------------------- APP - ADMIN - PUT USER
export async function putUser(data) {
  return initApi().then(() => {
    return axios.put(`${BASE_URL}/api/admin/user`, data);
  });
}
// ----------------------- APP - ADMIN - POST USER
export async function postUser(data) {
  return initApi().then(() => {
    return axios.post(`${BASE_URL}/api/admin/user`, data);
  });
}
// ----------------------- APP - ADMIN - GET ROLES
export async function getUserRoles() {
  return initApi().then(() => {
    return axios.get(`${BASE_URL}/api/admin/user/roles`);
  });
}
// ----------------------- APP - ADMIN - LOCK / UNLOCK USER
export async function lockUser(data) {
  return initApi().then(() => {
    return axios.put(`${BASE_URL}/api/admin/user/lock`, data);
  });
}