import axios from "../axios";
import { initApi, BASE_URL } from "../initApi";

// ----------------------- APP - GET MAIN CATEGORIES
export async function getProductCategories() {
  return initApi().then(() => {
    return axios.get(`${BASE_URL}/api/product-category/main`);
  });
}

// ----------------------- APP- GET SUBCATEGORIES
export async function getSubCategory() {
    return initApi().then(() => {
      return axios.get(`${BASE_URL}/api/product-category/sub`);
    });
  }