import axios from "../axios";
import { initApi, BASE_URL } from "../initApi";

// ----------------------- APP - USER - USER
export async function user(data) {
  return initApi().then(() => {
    return axios.post(`${BASE_URL}/api/user`, data);
  });
}
// ----------------------- APP - USER - AUTHENTICATE
export async function authenticate(data) {
  return initApi().then(() => {
    return axios.post(`${BASE_URL}/api/user/authenticate`, data);
  });
}
// ----------------------- APP - USER - REGISTER
export async function register(data) {
  return initApi().then(() => {
    return axios.post(`${BASE_URL}/api/user/register`, data);
  });
}
// ----------------------- APP - USER - REGISTER
export async function registerWithCompany(data) {
  return initApi().then(() => {
    return axios.post(`${BASE_URL}/api/user/register-with-company`, data);
  });
}
// ----------------------- APP - USER - ME
export async function me(data) {
  return initApi().then(() => {
    return axios.get(`${BASE_URL}/api/user/me`);
  });
}
// ----------------------- APP - USER - LOGOUT
export async function logout(data) {
  return initApi().then(() => {
    return axios.post(`${BASE_URL}/api/user/logout`);
  });
}
// ----------------------- APP - USER - POST GET COMPANY BY CUSTOMERNO + ZIP
export async function getCompanyByCustomerNo(data) {
  return initApi().then(() => {
    return axios.post(`${BASE_URL}/api/user/company`, data);
  });
}