import axios from "../axios";
import { initApi, BASE_URL } from "../initApi";

// ----------------------- APP - PASSWORD RESET - REQUEST PASSWORD RESET
export async function requestPasswordReset(data) {
  return initApi().then(() => {
    return axios.post(
      `${BASE_URL}/api/reset-password/email`,
      data
    );
  });
}
// ----------------------- APP - PASSWORD RESET - RESET PASSWORD
export async function resetPassword(data) {
  return initApi().then(() => {
    return axios.post(`${BASE_URL}/api/reset-password`, data);
  });
}
