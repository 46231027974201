import * as config from "./apiConfig";

class ConnectionMonitor {
  constructor() {
    this.events = [];
    this.maxEvents = 4;
  }

  addNetworkEvent(status, url, time, httpStatus) {
    this.events.push({
      status: status,
      url: url,
      time: time,
      httpStatus: httpStatus
    });

    this.events = this.events.slice(-this.maxEvents);
  }

  isOk() {
    if (this.events.length > 0) {
      return this.events[this.events.length - 1].status === "ok";
    }

    return true;
  }

  isSlow() {
    let slow = 0;

    for (let event of this.events) {
      if (event.status === "ok" && event.time > config.SLOW_TIME_THRESHOLD_MS) {
        ++slow;
      }
    }

    return slow > 0;
  }

  isDown() {
    if (this.events.length > 0) {
      return this.events[this.events.length - 1].status === "timeout";
    }

    return true;
  }
}

export default new ConnectionMonitor();
