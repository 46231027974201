import { combineReducers, createStore, applyMiddleware } from "redux";
import { routerReducer } from "react-router-redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";

import * as reducers from "./reducers";
import dynamicReducers from "./reducers";

let rootReducer = combineReducers({
  ...reducers,
  ...dynamicReducers,
  routing: routerReducer,
});

const composeEnhancers = composeWithDevTools({
  // Specify name here, actionsBlacklist, actionsCreators and other options if needed
});

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

if (module.hot) {
  module.hot.accept("./reducers", () => {
    import("./reducers").then((reducers) => {
      store.replaceReducer(
        combineReducers({
          ...reducers,
          routing: routerReducer,
        })
      );
    });
  });
}

export { store };
