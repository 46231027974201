import axios from "../axios";
import { initApi, BASE_URL } from "../initApi";
import { saveAs } from "file-saver";
import JSZip from "jszip";


// ----------------------- APP - RESOURCES - GET RESOURCE
export async function getResource(data) {
  return initApi().then(() => {
    return axios
      .get(`${BASE_URL}/api/resources${data.path}`, {
        headers: {
          Accept: "application/" + data.type,
        },
        responseType: "blob",
      })
      .then((response) => {
        const blob = new Blob([response.data], {
          type: "application/" + data.type,
        });
        saveAs(blob, data.name);
      });
  });
}

export async function getCadZip(productPaths) {
  return initApi().then(() => {
    const requestArray = productPaths.map((path) => {
      // console.log("path", path);
      return axios.get(`${BASE_URL}/api/resources${path}`, {
        headers: {
          Accept: "text/plain",
        },
        responseType: "application/json",
      });
    });
    return Promise.all(requestArray).then(response => {
      const title = "CAD";
      const zip = new JSZip();

      response.forEach((obj, index) => {

        const blob = new Blob([obj.data], {
          type: "application/json",
        });

        zip.file(productPaths[index].replace(new RegExp("/.*/"), ""), blob);
        
      });

      zip.generateAsync({type:"blob"})
      .then(function(content) {
        saveAs(content, title + ".zip");
      });
    });
  });
};
