import { createSelector } from "reselect";
import { version } from "../../package.json";
import { apiserver } from "../api/apiServer";

const PREFIX = version + "_";
const STATS_SET_FILES = `${PREFIX}STATS_SET_FILES`;

export const getReducerName = () => PREFIX + "statsReducer";
export const getStatsReducerState = (state) => state[getReducerName()];

export const initialState = {
  statsFiles: [],
};

// -------------- GETTERS --------------
export const getStatsFiles = createSelector(
  getStatsReducerState,
  (statsReducer) => statsReducer.statsFiles
);


// -------------- SETTERS --------------
export function setStatsFiles(data) {
  return {
    type: STATS_SET_FILES,
    data,
  };
}

// -------------- HANDLERS --------------
export function fetchStatsFiles() {
  return async (dispatch) => {
    let response = undefined;
    try {
      response = await apiserver.statistics.getFileStats();
    } catch (e) {
      console.log({ e });
      return;
    }
    dispatch(setStatsFiles(response.data));
  };
}
export function downloadFileStats() {
  return async (dispatch) => {
    let response = undefined;
    try {
      response = await apiserver.statistics.downloadFileStats();
    } catch (e) {
      console.log({ e });
      return;
    }
  };
}


export function reducer(state = initialState, action) {
  switch (action.type) {
    case STATS_SET_FILES:
      return {
        ...state,
        statsFiles: action.data,
      };
    default:
      return state;
  }
}
