import { createSelector } from "reselect";
import { version } from "../../package.json";
import { apiserver } from "../api/apiServer";

const PREFIX = version + "_";
const GLOBAL_ADM_SET_USERLIST = `${PREFIX}GLOBAL_ADM_SET_USERLIST`;
const GLOBAL_ADM_SET_USERLIST_META = `${PREFIX}GLOBAL_ADM_SET_USERLIST_META`;
const GLOBAL_ADM_SET_CURRENT_USER = `${PREFIX}GLOBAL_ADM_SET_CURRENT_USER`;
const GLOBAL_ADM_SET_ROLES = `${PREFIX}GLOBAL_ADM_SET_ROLES`;
const GLOBAL_ADM_SET_LANDING_PAGE_CAROUSEL = `${PREFIX}GLOBAL_ADM_SET_LANDING_PAGE_CAROUSEL`;

export const getReducerName = () => PREFIX + "globalAdmReducer";
export const getUserAdmReducerState = (state) => state[getReducerName()];

export const initialState = {
  userListMeta: {
    pageRequest: { page: 1, size: 10 },
    userFilter: {
      email: "",
      company: "",
      customerNumber: "",
    },
  },
  userList: {},
  currentUser: {},
  userRoles: [],
  landingPageCarousel:[],
};

// -------------- GETTERS --------------
export const getUserList = createSelector(
  getUserAdmReducerState,
  (globalAdmReducer) => globalAdmReducer.userList
);
export const getUserListMeta = createSelector(
  getUserAdmReducerState,
  (globalAdmReducer) => globalAdmReducer.userListMeta
);
export const getCurrentUser = createSelector(
  getUserAdmReducerState,
  (userAdmReducer) => userAdmReducer.currentUser
);
export const getUserRoles = createSelector(
  getUserAdmReducerState,
  (userAdmReducer) => userAdmReducer.userRoles
);
export const getLandingPageCarousel = createSelector(
  getUserAdmReducerState,
  (userAdmReducer) => userAdmReducer.landingPageCarousel
);


// -------------- SETTERS --------------
export function setCurrentUser(data) {
  return {
    type: GLOBAL_ADM_SET_CURRENT_USER,
    data,
  };
}
export function setUserRoles(data) {
  return {
    type: GLOBAL_ADM_SET_ROLES,
    data,
  };
}

export function setUserList(data) {
  return {
    type: GLOBAL_ADM_SET_USERLIST,
    data,
  };
}
export function setUserListMeta(data) {
  return {
    type: GLOBAL_ADM_SET_USERLIST_META,
    data,
  };
}
export function setLandingPageCarousel(data) {
  return {
    type: GLOBAL_ADM_SET_LANDING_PAGE_CAROUSEL,
    data,
  };
}

// -------------- HANDLERS --------------
export function fetchGlobalUserList(data) {
  return async (dispatch, getState) => {
    const state = getState()[getReducerName()];
    const requestParams = {
      pageRequest: { ...state.userListMeta.pageRequest, ...data?.pageRequest },
      userFilter: { ...state.userListMeta.userFilter, ...data?.userFilter },
    };
    
    let response = undefined;
    try {
      response = await apiserver.globalAdmin.getGlobalUserList(requestParams);
    } catch (e) {
      console.log({ e });
      return;
    }
    dispatch(setUserList(response.data));
    dispatch(setUserListMeta(requestParams));
  };
}

export function fetchCurrentUser(data) {
  return async (dispatch) => {
    let response = undefined;
    try {
      response = await apiserver.globalAdmin.getUserByID(data.id);
    } catch (e) {
      console.log({ e });
      return;
    }
    dispatch(setCurrentUser(response.data));
  };
}
export function changeUserInformation(data) {
  /* return async (dispatch, getState) => {
    const state = getState();
    const currentUser = state[getReducerName()].currentUser;

    try {
      await apiserver.globalAdmin.putUser(data);
    } catch (e) {
      console.log({ e });
      return;
    }
    dispatch(fetchCurrentUser(currentUser));
  }; */
  return async (dispatch, getState) => {
    const state = getState();
    const currentUser = state[getReducerName()].currentUser;
    const newCurrentUser = { ...currentUser, ...data };

    dispatch(setCurrentUser(newCurrentUser));
  }; 
}

export function submitUserInformation(data) {
  return async (dispatch, getState) => {
    const state = getState();
    const currentUser = state[getReducerName()].currentUser;
    const formattedCurrentUser = {
      id: currentUser.id,
      salutation: currentUser.salutation,
      phoneNumber: currentUser.phoneNumber,
      mobilePhoneNumber: currentUser.mobilePhoneNumber,
      fax: currentUser.fax,
      email: currentUser.email,
      firstName: currentUser.firstName,
      lastName: currentUser.lastName,
      password: currentUser.password,
      roles: currentUser.roles,
    };
    try {
      await apiserver.globalAdmin.putUser(formattedCurrentUser);
    } catch (e) {
      console.log({ e });
      data?.callback({
        isSnackbarOpen: true,
        msgSnackbar: "msgUpdateUserError",
        severity: "error",
      });
      return;
    }
    data?.callback({
      isSnackbarOpen: true,
      msgSnackbar: "msgUpdateUserSuccess",
      severity: "success",
    });
    dispatch(
      handleChangeCompany({
        params:
          {
            userId: currentUser.id,
            customerNumber: currentUser.companyCustomerNumber,
        },
        callback:data?.callback
      })
    );
    //dispatch(fetchCurrentUser(currentUser));
  };
}

export function fetchUserRoles() {
  return async (dispatch) => {
    let response = undefined;
    try {
      response = await apiserver.globalAdmin.getUserRoles();
    } catch (e) {
      console.log({ e });
      return;
    }
    dispatch(setUserRoles(response.data));
  };
}

export function handleDeleteUser(data) {
  return async (dispatch) => {
    if (data?.id) {
      try {
        await apiserver.globalAdmin.deleteUser(data.id);
      } catch (e) {
        console.log({ e });
        data?.callback({
          isSnackbarOpen: true,
          msgSnackbar: "msgUserDeleteError",
          severity: "error",
        });
        return;
      }
      data?.callback({
        isSnackbarOpen: true,
        msgSnackbar: "msgUserDeleteSuccess",
        severity: "success",
      });
      dispatch(fetchGlobalUserList());
    }
  };
}

export function handleLockUser(data) {
  return async (dispatch) => {
    if (data?.params?.userId) {
      try {
        await apiserver.globalAdmin.lockUser(data.params);
      } catch (e) {
        console.log({ e });
        data?.callback({
          isSnackbarOpen: true,
          msgSnackbar: "msgUserLockError",
          severity: "error",
        });
        return;
      }
      data?.callback({
        isSnackbarOpen: true,
        msgSnackbar:
          data.params.locking === true
            ? "msgUserLockSuccess"
            : "msgUserUnlockSuccess",
        severity: "success",
      });
      dispatch(fetchGlobalUserList());
    }
  };
}

export function handleChangeCompany(data) {
  return async (dispatch) => {
    if (data?.params?.userId && data?.params?.customerNumber) {
      try {
        await apiserver.globalAdmin.changeCompany(data.params);
      } catch (e) {
        console.log({ e });
        data?.callback({
          isSnackbarOpen: true,
          msgSnackbar: "msgChangeCompanyError",
          severity: "error",
        });
        return;
      }
      data?.callback({
        isSnackbarOpen: true,
        msgSnackbar: "msgChangeCompanySuccess",
        severity: "success",
      });
      dispatch(fetchCurrentUser({ id: data.params.userId }));
    }
  };
}

export function fetchLandingPageCarousel() {
  return async (dispatch) => {
    let response = undefined;
    try {
      response = await apiserver.knarr.getCarouselSlidesAll()
    } catch (e) {
      console.log({ e });
      return;
    }
    const landingPageCarouselSorted = response.data.sort((a, b) => {
      return a.sortIndex - b.sortIndex;
    });
    dispatch(setLandingPageCarousel(landingPageCarouselSorted));
  }
}
export function deleteLandingPageCarouselSlide(data) {
  return async (dispatch, getState) => {
    const state = getState();
    const landingPageCarousel = [...state[getReducerName()].landingPageCarousel];

    const { slideId, callback} = data;
    if (!slideId) {
      console.warn("Invalid slideId:" + slideId);
    }

    try {
      await apiserver.knarr.deleteCarouselSlide(slideId)
    } catch (e) {
      console.log({ e });
      callback({
        isSnackbarOpen: true,
        msgSnackbar: "msgDeleteError",
        severity: "error",
      });
      return;
    }
    
    const slideIndex = landingPageCarousel.findIndex(
      (slide) => slide.id === slideId
    );
    landingPageCarousel.splice(slideIndex, 1)

    callback({
      isSnackbarOpen: true,
      msgSnackbar: "msgDeleteSuccess",
      severity: "success",
    });


    dispatch(setLandingPageCarousel(landingPageCarousel));
  }
}


export function addLandingPageCarouselSlide() {
  return async (dispatch, getState) => {
    const state = getState();
    const landingPageCarousel = [...state[getReducerName()].landingPageCarousel];
    
    const slideData = {
      sortIndex: 999,
      isHidden: true,
      landingPageTexts: [
        {
          languageCode: "",
          imageText1: "",
          imageText2: "",
          buttonText: "",
          buttonUrl: "",
        },
      ],
    };
    
    let response = undefined;
    try {
      response = await apiserver.knarr.postCarouselSlides(slideData);
    } catch (e) {
      console.log({ e });
      return;
    }
    landingPageCarousel.push(response.data);
    dispatch(setLandingPageCarousel(landingPageCarousel));
  };
}

export function updateLandingPageCarouselSlideImage(data) {
  return async (dispatch, getState) => {
    const state = getState();
    const landingPageCarousel = [...state[getReducerName()].landingPageCarousel];
    const { file, slideId, callback } = data;
    const slideIndex = landingPageCarousel.findIndex(
      (slide) => slide.id === slideId
    );

    let response = undefined;
    try {
      response = await apiserver.knarr.putCarouselSlideImage({ file, slideId });
    } catch (e) {
      console.log({ e });
      callback({
        isSnackbarOpen: true,
        msgSnackbar: "msgSaveError",
        severity: "error",
      });
      return;
    }
    
    landingPageCarousel[slideIndex] = response.data;
    callback({
      isSnackbarOpen: true,
      msgSnackbar: "msgSaveSuccess",
      severity: "success",
    });
    dispatch(setLandingPageCarousel(landingPageCarousel));
  };
}
export function updateLandingPageCarouselSlide(data) {
  return async (dispatch, getState) => {
    const state = getState();
    const landingPageCarousel = [...state[getReducerName()].landingPageCarousel];
    const { slideId, callback } = data;
    const slideIndex = landingPageCarousel.findIndex(slide => slide.id === slideId);
    const slide = landingPageCarousel[slideIndex];
    const body = {
      sortIndex: slide.sortIndex,
      isHidden: slide.isHidden,
      landingPageTexts: [...slide.texts]
    }
    let response = undefined;
    try {
      response = await apiserver.knarr.putCarouselSlideText({ slideId, body });
    } catch (e) {
      console.log({ e });
      callback({
        isSnackbarOpen: true,
        msgSnackbar: "msgSaveSuccess",
        severity: "success",
      });
      return;
    }
    landingPageCarousel[slideIndex] = response.data;
    const landingPageCarouselSorted = landingPageCarousel.sort((a, b) => {
      return a.sortIndex - b.sortIndex
    })
    callback({
      isSnackbarOpen: true,
      msgSnackbar: "msgSaveSuccess",
      severity: "success",
    });

    dispatch(setLandingPageCarousel(landingPageCarouselSorted));
  };
}
export function changeLandingPageCarouselSlideText(data) {
  return async (dispatch, getState) => {
    const state = getState();
    const landingPageCarousel = [...state[getReducerName()].landingPageCarousel];
    const { slideId, languageCode, key, value } = data;

    const slideIndex = landingPageCarousel.findIndex(slide => slide.id === slideId);
    const languageIndex = landingPageCarousel[slideIndex].texts.findIndex(
      (textGrp) => textGrp.languageCode === languageCode
    );
    landingPageCarousel[slideIndex].texts[languageIndex][key] = value;

    dispatch(setLandingPageCarousel(landingPageCarousel));
  }
}
export function changeLandingPageCarouselSlideData(data) {
  return async (dispatch, getState) => {
    const state = getState();
    const landingPageCarousel = [...state[getReducerName()].landingPageCarousel];
    const { slideId, value, key } = data;

    const slideIndex = landingPageCarousel.findIndex(slide => slide.id === slideId);
    landingPageCarousel[slideIndex][key] = value;

    dispatch(setLandingPageCarousel(landingPageCarousel));
  }
}

export function reducer(state = initialState, action) {
  switch (action.type) {
    case GLOBAL_ADM_SET_USERLIST:
      return {
        ...state,
        userList: action.data,
      };
    case GLOBAL_ADM_SET_USERLIST_META:
      return {
        ...state,
        userListMeta: action.data,
      };
    case GLOBAL_ADM_SET_CURRENT_USER:
      return {
        ...state,
        currentUser: action.data,
      };
    case GLOBAL_ADM_SET_ROLES:
      return {
        ...state,
        userRoles: action.data,
      };
    case GLOBAL_ADM_SET_LANDING_PAGE_CAROUSEL:
      return {
        ...state,
        landingPageCarousel: action.data,
      };
    default:
      return state;
  }
}
