import axios from "../axios";
import { initApi, BASE_URL } from "../initApi";
import { saveAs } from "file-saver";

// ----------------------- APP - STATISTICS - GET FILE STATISTICS
export async function getFileStats() {
  return initApi().then(() => {
    return axios.get(`${BASE_URL}/api/statistics/files`);
  });
}

// ----------------------- APP - STATISTICS - DOWNLOAD FILE STATISTICS
export async function downloadFileStats() {
  return initApi().then(() => {
    return axios
      .get(`${BASE_URL}/api/statistics/files/excel`, {
        headers: {
          Accept:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        },
        responseType: "blob",
      })
      .then((response) => {
        const blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(
          blob,
          "knarr-webshop-statistics-" + new Date().toISOString().replace(/:/g, "-") + ".xlsx"
        );
      });
  });
}
