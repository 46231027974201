import axios from "../axios";
import { initApi, BASE_URL } from "../initApi";

// ----------------------- ORDER - GET ORDER HISTORY
export async function getOrderHistory(data) {
  return initApi().then(() => {
    return axios.post(`${BASE_URL}/api/order/list`, data);
  });
}

// ----------------------- ORDER - GET ORDER
export async function getOrder(orderId) {
  return initApi().then(() => {
    return axios.get(`${BASE_URL}/api/order/${orderId}`);
  });
}

// ----------------------- ORDER - CART - SUBMIT ORDER
export async function submitOrder(data) {
  return initApi().then(() => {
    return axios.post(`${BASE_URL}/api/order`, data);
  });
}

// ----------------------- ORDER - CART - SUBMIT ORDER
export async function reorder(data) {
  return initApi().then(() => {
    return axios.post(`${BASE_URL}/api/order/reorder`, data);
  });
}

// ----------------------- ORDER - CART - GET SHIPPING COSTS DEPRECATED
export async function getShippingCosts(data) { //DEPRECATED
  // moved to /api/cart (cartOptions)
  return initApi().then(() => { //DEPRECATED
    return axios.post(`${BASE_URL}/api/order/shipping`, data); //DEPRECATED
  });
}