import { createSelector } from "reselect";
import { version } from "../../package.json";
import { apiserver } from "../api/apiServer";
import Parser from "rss-parser";

const PREFIX = version + "_";
const APP_SET_META = `${PREFIX}APP_REDUCER_FETCH_META`;
const APP_SET_SEARCH_META = `${PREFIX}APP_SET_SEARCH_META`;
const APP_FETCH_PRODUCT_CATEGORIES = `${PREFIX}APP_FETCH_PRODUCT_CATEGORIES`;
const APP_SET_PRODUCT_DATA = `${PREFIX}APP_SET_PRODUCT_DATA`;
const APP_SET_PRODUCT_FILTER = `${PREFIX}APP_SET_PRODUCT_FILTER`;
const APP_SET_SEARCH_RESULTS = `${PREFIX}APP_SET_SEARCH_RESULTS`;
const APP_SET_SEARCH_SUGGESTIONS = `${PREFIX}APP_SET_SEARCH_SUGGESTIONS`;
const APP_SET_PRODUCT_CATEGORY_DATA = `${PREFIX}APP_SET_PRODUCT_CATEGORY_DATA`;
const APP_SET_PRODUCT_CATEGORY_DEALS = `${PREFIX}APP_SET_PRODUCT_CATEGORY_DEALS`;
const APP_FETCH_LANGUAGE = `${PREFIX}APP_REDUCER_FETCH_LANGUAGE`;
const APP_FETCH_LANGUAGE_LIST = `${PREFIX}APP_FETCH_LANGUAGE_LIST`;
const APP_SET_SIDECART_META = `${PREFIX}APP_SET_SIDECART_META`;
const APP_SET_LISTSTYLE = `${PREFIX}APP_SET_LISTSTYLE`;
const APP_SET_FEEDS = `${PREFIX}APP_SET_FEEDS`;
const APP_SET_LANDINGPAGE_CAROUSEL = `${PREFIX}APP_SET_LANDINGPAGE_CAROUSEL`;

export const getReducerName = () => PREFIX + "appReducer";
export const getAppReducerState = (state) => state[getReducerName()];

export const initialState = {
  meta: {
    newsletterSubscribed: false,
    loadingSearch: false,
    landingPageDeals: {
      result: {
        data: []
      }
    },
    showPartListImportModal: false,
  },
  sideCartMeta: { isOpen: false },
  productCategoryData: {}, //Data about certain product categories
  productCategoryDeals: {}, //Data about certain product categories
  productData: {}, //Data about certain products
  productCategoriesArr: [], //Array of product categories and subcategories used mainly for navigation
  productFilter: {
    selectedFilters: [],
    currentFilters: {}
  },
  language: {},
  languageList: [],
  searchMeta: {
    query: "",
    page: 1,
    size: 999,
    lastQuery:""
  },
  searchResults: {},
  searchSuggestions: [],
  feeds: {},
  landingPageCarousel: [],
  listStyle: localStorage.getItem("productListView") || "box"
};

export const getMeta = createSelector(
  getAppReducerState,
  (appReducer) => appReducer.meta
);

export const getSideCartMeta = createSelector(
  getAppReducerState,
  (appReducer) => appReducer.sideCartMeta
);
export const getFeeds = createSelector(
  getAppReducerState,
  (appReducer) => appReducer.feeds
);
export const getLandingpageCarousel = createSelector(
  getAppReducerState,
  (appReducer) => appReducer.landingPageCarousel
);

export const getProductCategories = createSelector(
  getAppReducerState,
  (appReducer) => appReducer.productCategoriesArr
);
export const getProductCategoryData = createSelector(
  getAppReducerState,
  (appReducer) => appReducer.productCategoryData
);
export const getProductCategoryDeals = createSelector(
  getAppReducerState,
  (appReducer) => appReducer.productCategoryDeals
);
export const getProductData = createSelector(
  getAppReducerState,
  (appReducer) => appReducer.productData
);
export const getProductFilter = createSelector(
  getAppReducerState,
  (appReducer) => appReducer.productFilter
);
export const getSearchResults = createSelector(
  getAppReducerState,
  (appReducer) => appReducer.searchResults
);
export const getSearchSuggestions = createSelector(
  getAppReducerState,
  (appReducer) => appReducer.searchSuggestions
);
export const getSearchMeta = createSelector(
  getAppReducerState,
  (appReducer) => appReducer.searchMeta
);
export const getLanguage = createSelector(
  getAppReducerState,
  (appReducer) => appReducer.language
);
export const getLanguageList = createSelector(
  getAppReducerState,
  (appReducer) => appReducer.languageList
);
export const getListStyle = createSelector(
  getAppReducerState,
  (appReducer) => appReducer.listStyle
);

export function setMeta(data) {
  return {
    type: APP_SET_META,
    data,
  };
}
export function setFeeds(data) {
  return {
    type: APP_SET_FEEDS,
    data,
  };
}
export function setLandingpageCarousel(data) {
  return {
    type: APP_SET_LANDINGPAGE_CAROUSEL,
    data,
  };
}
export function setSearchMeta(data) {
  return {
    type: APP_SET_SEARCH_META,
    data,
  };
}
export function setProductCategoriesArr(data) {
  return {
    type: APP_FETCH_PRODUCT_CATEGORIES,
    data,
  };
}
export function setProductCategoryData(data) {
  return {
    type: APP_SET_PRODUCT_CATEGORY_DATA,
    data,
  };
}
export function setCategoryDeals(data) {
  return {
    type: APP_SET_PRODUCT_CATEGORY_DEALS,
    data,
  };
}
export function setLanguage(data) {
  return {
    type: APP_FETCH_LANGUAGE,
    data,
  };
}
export function setLanguageList(data) {
  return {
    type: APP_FETCH_LANGUAGE_LIST,
    data,
  };
}
export function setProductData(data) {
  return {
    type: APP_SET_PRODUCT_DATA,
    data,
  };
}
export function setProductFilter(data) {
  const newFilterObj = {}
  newFilterObj[data.categoryId] = data
  return {
    type: APP_SET_PRODUCT_FILTER,
    data: newFilterObj,
  };
}
export function setSearchResults(data) {
  return {
    type: APP_SET_SEARCH_RESULTS,
    data,
  };
}
export function setSearchSuggestions(data) {
  return {
    type: APP_SET_SEARCH_SUGGESTIONS,
    data,
  };
}
export function setSideCartMeta(data) {
  return {
    type: APP_SET_SIDECART_META,
    data,
  };
}
export function setListStyle(data) {
  return {
    type: APP_SET_LISTSTYLE,
    data,
  };
}


export function updateMeta(data) {
  return async (dispatch) => {
    dispatch(setMeta(data));
  };
}
export function updateSideCartMeta(data) {
  return async (dispatch, getState) => {
    const state = getState()[getReducerName()];
    const sideCartMeta = { ...state.sideCartMeta };
    dispatch(setSideCartMeta({ ...sideCartMeta, ...data}));
  };
}

export function fetchResource(resourceObj) {
  if (resourceObj.path && resourceObj.name) {
    return async (dispatch) => {
      try {
        await apiserver.resources.getResource({
          path: resourceObj.path,
          type: resourceObj.path.toLowerCase(),
          name: resourceObj.name
        });
      } catch (e) {
        console.log({ e });
        return;
      }
    };
  }
}

export function fetchProductFilters(queryObj) {
  return async (dispatch, getState) => {
    const { categoryId, subCategoryIds } = queryObj;
    const state = getState()[getReducerName()];
    const filters = state.productFilter?.[categoryId] || state.productFilter;
    let selectedFilters = [...filters.selectedFilters];
/* 
    if (queryObj.categoryId !== filters.categoryId) {
      selectedFilters = [];
    } */

    let response = undefined;
    try {
      response = await apiserver.product.filterProduct({
        filters: selectedFilters,
        pageRequest: { size: 999 },
        categoryId,
        subCategoryIds,
      });
    } catch (e) {
      console.log({ e });
      return;
    }

    const currentFilters = {};
    if (
      response.data.selectedFilters &&
      response.data.selectedFilters.length > 0
    ) {
      response.data.selectedFilters.forEach((filter) => {
        currentFilters[filter.name] = filter.values;
      });
    }

    dispatch(
      setProductFilter({
        ...response.data,
        currentFilters,
        categoryId,
        subCategoryIds
      })
    );

  };
}
export function resetCurrentProductFilters() {
  //let { categoryId, subCategoryIds } = queryObj; //TODO
  return async (dispatch) => {
    dispatch(
      setProductFilter({
        currentFilters: {},
        selectedFilters: [],
      })
    );
  };
}
export function updateCurrentProductFilters({catId, event}) {
  return async (dispatch, getState) => {
    const state = getState()[getReducerName()];
    const filters = state.productFilter?.[catId];

    const newCurrentFilters = { ...filters.currentFilters };
    if (event.value !== null) {
      newCurrentFilters[event.key] = event.value;
    } else {
      delete newCurrentFilters[event.key];
    }

    const newSelectedFilters = Object.keys(newCurrentFilters).map(key => {
      return {
        name: key,
        values: Array.isArray(newCurrentFilters[key])
          ? newCurrentFilters[key]
          : [newCurrentFilters[key]],
      };
    })

    dispatch(
      setProductFilter({
        categoryId: catId,
        ...filters,
        currentFilters: newCurrentFilters,
        selectedFilters: newSelectedFilters
      })
    );
    if (event.commit === true) {
      dispatch(
        fetchCategoryData({
          categoryId: filters.categoryId,
          subCategoryIds: filters.subCategoryIds || [],
        })
      );
    }
  };
}
export function handleNewsletterSignup() {
  return async (dispatch, getState) => {
    const state = getState();
    const meta = { ...state[getReducerName()].meta };
    console.log("dummy - handleNewsletterSignup"); //TODO handleNewsletterSignup

    meta.newsletterSubscribed = !meta.newsletterSubscribed

    dispatch(setMeta(meta));
  };
}
export function handleLanguagePut(data) {
  return async (dispatch) => {
    try {
      await apiserver.language.putLanguage(data);
    } catch (e) {
      console.log({ e });
      return;
    }
    // dispatch(setLanguage());
    window.location.reload();
  };
}

export function handleListStyleChange() {
  return async (dispatch) => {
    const currentStatus = localStorage.getItem("productListView")
    let newStatus = "list"
    if (currentStatus === "box") {
      newStatus = "list"
    } else if (currentStatus === "list") {
      newStatus = "box"
    } 
    console.log("currentStatus",currentStatus);
    console.log("newStatus",newStatus);
    localStorage.setItem("productListView", newStatus)
    dispatch(setListStyle(newStatus));
  };
}

export function fetchMainCategory() {
  return async (dispatch) => {
    let response = undefined;
    try {
      response = await apiserver.productCategory.getProductCategories();
    } catch (e) {
      console.log({ e });
      return;
    }
    dispatch(setProductCategoriesArr(response.data));
  };
}
export function searchProducts(queryObj) {
  return async (dispatch, getState) => {
    const state = getState()[getReducerName()];
    const searchMeta = state.searchMeta;
    let response = undefined;
    dispatch(setMeta({ loadingSearch: true }));
    try {
      response = await apiserver.product.searchProduct({
        query: searchMeta.query,
        pageRequest: { size: searchMeta.size, page: searchMeta.page },
        ...queryObj,
      });
    } catch (e) {
      console.log({ e });
      dispatch(setMeta({ loadingSearch: false }));
      return;
    }
    dispatch(setMeta({ loadingSearch: false }));
    dispatch(setSearchResults(response.data));
  };
}
export function searchProductsAutoComplete(data) {
  return async (dispatch) => {
    const { searchStr, callback } = data;
    let response = undefined;

    callback({ loadingAutoComplete: true })
    
    try {
      response = await apiserver.product.autoComplete(searchStr);
    } catch (e) {
      console.log({ e });
      callback({ loadingAutoComplete: false });
      return;
    }

    callback({ loadingAutoComplete: false });
    dispatch(setSearchSuggestions(response.data));
  };
}
export function fetchCategoryData(queryObj) {
  return async (dispatch, getState) => {
    const state = getState()[getReducerName()];
    let {categoryId, subCategoryIds} = queryObj
    const filters = state.productFilter?.[categoryId] || state.productFilter;
    let selectedFilters = [...filters.selectedFilters];

    dispatch(setProductCategoryData({}));
    /* if (queryObj.resetFilters === true) {
      //todo second reset?
      console.log("fetchCategoryData reset");
      dispatch(
        setProductFilter({
          categoryId: categoryId,
          selectedFilters: [],
          currentFilters: {},
          subCategoryIds: []
        })
      );
    } */

    /* if (categoryId !== filters.categoryId) {
      selectedFilters = [];
      subCategoryIds = queryObj.subCategoryIds || [];
    } */
    dispatch(
      fetchProductFilters({
        categoryId: queryObj.categoryId,
        subCategoryIds: queryObj.subCategoryIds || filters?.subCategoryIds,
      })
    );

    let responseCatData = undefined;
    try {
      responseCatData = await apiserver.product.searchProduct({
        pageRequest: { size: 999 },
        filters: selectedFilters,
        categoryId: queryObj.categoryId,
        subCategoryIds: queryObj.subCategoryIds || filters?.subCategoryIds,
      });
    } catch (e) {
      console.log({ e });
      return;
    }
    dispatch(setProductCategoryData(responseCatData.data));
    
  };
}
export function fetchCategoryDeals(queryObj) {
  return async (dispatch) => {
    dispatch(setCategoryDeals({}));
    let response = undefined;
    try {
      response = await apiserver.product.searchProduct({
        pageRequest: { size: 999 },
        dealsOnly: true,
        ...queryObj
      });
    } catch (e) {
      console.log({ e });
      return;
    }
    dispatch(setCategoryDeals(response.data));
  };
}
export function fetchLandingPageDeals() {
  return async (dispatch, getState) => {
    let response = undefined;
    try {
      response = await apiserver.product.getLandingPageDealsRandom(6);
    } catch (e) {
      console.log({ e });
      return;
    }

    dispatch(
      setMeta({ landingPageDeals: { result: { data: response.data } } })
    );
  }
}


export function fetchProductData(prodNr) {
  return async (dispatch) => {
    let prodResponse = undefined;
    try {
      prodResponse = await apiserver.product.getProduct(prodNr);
    } catch (e) {
      console.log({ e });
      return;
    }
    let tableMappingResponse = undefined;
    try {
      tableMappingResponse =
        await apiserver.dimensionTableMapping.dimensionTableMapping(prodNr);
    } catch (e) {
      console.log({ e });
      return;
    }
    let productDimensionResponse = undefined;
    try {
      productDimensionResponse =
        await apiserver.productDimension.productDimension(prodNr);
    } catch (e) {
      console.log({ e });
      return;
    }
    const combinedProductData = {
      ...prodResponse.data,
      dimensionTableMapping: tableMappingResponse.data,
      productDimension: productDimensionResponse.data,
    };
    dispatch(setProductData(combinedProductData));
  };
}

export function fetchLandingpageCarousel(data) {
  return async (dispatch) => {
    let responseLandingpage = undefined;

    let uriParams = "lang=" + data.language;

    switch (data.language) {
      /* case "de":
        uriParams="";
        break; */
      case "pt":
        uriParams="lang=pt-pt";
        break;
      default:
        break;
    }

    try {
      responseLandingpage = await apiserver.knarr.getCarouselSlides(uriParams);
    } catch (e) {
      console.log({ e });
      return;
    }
    
    dispatch(setLandingpageCarousel(responseLandingpage.data));


  };
}
export function fetchFeed(data) {
  return async (dispatch) => {
    let responseFeed = undefined;

    let uriParams = "lang=" + data.language;
    const feedObj = {};

    switch (data.language) {
      case "de":
        uriParams="";
        break;
      case "pt":
        uriParams="lang=pt-pt";
        break;
      default:
        break;
    }

    try {
      responseFeed = await apiserver.knarr.getFeed(uriParams);
    } catch (e) {
      console.log({ e });
      return;
    }

    const parser = new Parser();

    parser.parseString(responseFeed.data, (err, feed) => {
      if (err) throw err;
      const formattedFeed = feed.items.map((item) => {
        const src = item.content
          .match(/src=".+\.(jpg|jpeg|png)"/)?.[0]?.replace(/(src=")|(\\"$)/, "")/* .replace("http:", "https:") */;
        const srcSet = item.content
          .match(/srcset=".+ \d+w"/)?.[0]?.replace(/(srcset=")|(\\"$)/, "")/* .replace("http:", "https:") */;

        return {
          title: item.title,
          link: item.link,
          pubDate: item.pubDate,
          //content: item.content,
          src,
          srcSet,
          //imagePaths,
        };
      });
      feedObj[data.name] = formattedFeed;
      dispatch(setFeeds(feedObj));
    });

  };
}

export function fetchLanguage() {
  return async (dispatch) => {
    let response = undefined;
    try {
      response = await apiserver.language.getLanguage();
    } catch (e) {
      console.log({ e });
      return;
    }
    dispatch(setLanguage(response.data));
  };
}

export function fetchLanguageList() {
  return async (dispatch) => {
    let response = undefined;
    try {
      response = await apiserver.language.getLanguageList();
    } catch (e) {
      console.log({ e });
      return;
    }
    dispatch(setLanguageList(response.data));
  }
}
export function handleSearchMetaChange(data) {
  return async (dispatch, getState) => {
    const state = getState()[getReducerName()];
    const lastQuery = data.query || state.searchMeta.lastQuery;
    const searchMeta = { ...state.searchMeta, ...data, lastQuery };
    dispatch(setSearchMeta(searchMeta));

    if (data?.commit === true){
      dispatch(setSearchResults({}));
      dispatch(
        searchProducts({
          query: searchMeta.query,
          pageRequest: {
            page: searchMeta.page,
            size: searchMeta.size,
          },
        })
      );
    }
  }
}

export function handleProductDimensionToleranceChange(data) {
  return async (dispatch, getState) => {
    const state = getState()[getReducerName()];
    const oldProductData = state.productData;
    const productData = { ...state.productData };
    const dimensionIndex = oldProductData.productDimension.findIndex((dim) => dim.dimension.id === data.dimensionId);
    let reqData = {
      dimensionId: data.dimensionId,
      changedLength: data.changedLength,
    };
    let response = undefined;
    try {
      response = await apiserver.productDimension.fetchTolerancePrice(reqData);
    } catch (e) {
      console.log({ e });
      return;
    }
    productData.productDimension[dimensionIndex].trimming.tolerance = response.data.tolerance;
    productData.productDimension[dimensionIndex].trimming.price = response.data.price;
    dispatch(setProductData(productData));
  };
}

export function reducer(state = initialState, action) {
  switch (action.type) {
    case APP_SET_META:
      return {
        ...state,
        meta: { ...state.meta, ...action.data },
      };
    case APP_SET_SEARCH_META:
      return {
        ...state,
        searchMeta: action.data,
      };
    case APP_FETCH_PRODUCT_CATEGORIES:
      return {
        ...state,
        productCategoriesArr: action.data,
      };
    case APP_SET_PRODUCT_CATEGORY_DATA:
      return {
        ...state,
        productCategoryData: action.data,
      };
    case APP_SET_PRODUCT_CATEGORY_DEALS:
      return {
        ...state,
        productCategoryDeals: action.data,
      };
    case APP_SET_PRODUCT_DATA:
      return {
        ...state,
        productData: action.data,
      };
    case APP_SET_PRODUCT_FILTER:
      const newProductFilters = { ...state.productFilter, ...action.data }
      return {
        ...state,
        productFilter: newProductFilters,
        //productFilter: action.data,
      };
    case APP_SET_SEARCH_RESULTS:
      return {
        ...state,
        searchResults: action.data,
      };
    case APP_SET_SEARCH_SUGGESTIONS:
      return {
        ...state,
        searchSuggestions: action.data,
      };
    case APP_FETCH_LANGUAGE:
      return {
        ...state,
        language: action.data,
      };
    case APP_FETCH_LANGUAGE_LIST:
      return {
        ...state,
        languageList: action.data,
      };
    case APP_SET_SIDECART_META:
      return {
        ...state,
        sideCartMeta: action.data,
      };
    case APP_SET_LISTSTYLE:
      return {
        ...state,
        listStyle: action.data,
      };
    case APP_SET_FEEDS:
      return {
        ...state,
        feeds: { ...state.feeds, ...action.data },
      };
    case APP_SET_LANDINGPAGE_CAROUSEL:
      return {
        ...state,
        landingPageCarousel: action.data,
      };
    default:
      return state;
  }
}
